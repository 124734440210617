import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../substitutions.js";
import * as dxos_agent_dashboard from "./agent/dashboard.js";
import * as dxos_agent_echoproxy from "./agent/echoproxy.js";
import * as dxos_agent_epoch from "./agent/epoch.js";
import * as dxos_agent_functions from "./agent/functions.js";
import * as dxos_bot from "./bot.js";
import * as dxos_client_services from "./client/services.js";
import * as dxos_config from "./config.js";
import * as dxos_devtools_host from "./devtools/host.js";
import * as dxos_devtools_swarm from "./devtools/swarm.js";
import * as dxos_echo_blob from "./echo/blob.js";
import * as dxos_echo_feed from "./echo/feed.js";
import * as dxos_echo_filter from "./echo/filter.js";
import * as dxos_echo_indexing from "./echo/indexing.js";
import * as dxos_echo_metadata from "./echo/metadata.js";
import * as dxos_echo_model_document from "./echo/model/document.js";
import * as dxos_echo_model_messenger from "./echo/model/messenger.js";
import * as dxos_echo_model_text from "./echo/model/text.js";
import * as dxos_echo_object from "./echo/object.js";
import * as dxos_echo_query from "./echo/query.js";
import * as dxos_echo_service from "./echo/service.js";
import * as dxos_echo_snapshot from "./echo/snapshot.js";
import * as dxos_echo_timeframe from "./echo/timeframe.js";
import * as dxos_edge_calls from "./edge/calls.js";
import * as dxos_edge_messenger from "./edge/messenger.js";
import * as dxos_edge_signal from "./edge/signal.js";
import * as dxos_error from "./error.js";
import * as dxos_gravity from "./gravity.js";
import * as dxos_halo_credentials from "./halo/credentials.js";
import * as dxos_halo_credentials_auth from "./halo/credentials/auth.js";
import * as dxos_halo_credentials_greet from "./halo/credentials/greet.js";
import * as dxos_halo_credentials_identity from "./halo/credentials/identity.js";
import * as dxos_halo_invitations from "./halo/invitations.js";
import * as dxos_halo_keyring from "./halo/keyring.js";
import * as dxos_halo_keys from "./halo/keys.js";
import * as dxos_halo_signed from "./halo/signed.js";
import * as dxos_keys from "./keys.js";
import * as dxos_mesh_bridge from "./mesh/bridge.js";
import * as dxos_mesh_broadcast from "./mesh/broadcast.js";
import * as dxos_mesh_messaging from "./mesh/messaging.js";
import * as dxos_mesh_muxer from "./mesh/muxer.js";
import * as dxos_mesh_presence from "./mesh/presence.js";
import * as dxos_mesh_protocol from "./mesh/protocol.js";
import * as dxos_mesh_replicator from "./mesh/replicator.js";
import * as dxos_mesh_signal from "./mesh/signal.js";
import * as dxos_mesh_swarm from "./mesh/swarm.js";
import * as dxos_mesh_teleport from "./mesh/teleport.js";
import * as dxos_mesh_teleport_auth from "./mesh/teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "./mesh/teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "./mesh/teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "./mesh/teleport/control.js";
import * as dxos_mesh_teleport_gossip from "./mesh/teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "./mesh/teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "./mesh/teleport/replicator.js";
import * as dxos_registry from "./registry.js";
import * as dxos_rpc from "./rpc.js";
import * as dxos_service_agentmanager from "./service/agentmanager.js";
import * as dxos_service_publisher from "./service/publisher.js";
import * as dxos_service_supervisor from "./service/supervisor.js";
import * as dxos_service_tunnel from "./service/tunnel.js";
import * as dxos_tracing from "./tracing.js";
import * as dxos_type from "./type.js";
import * as dxos_value from "./value.js";
import * as example_testing_data from "../example/testing/data.js";
import * as example_testing_rpc from "../example/testing/rpc.js";
import * as google_protobuf from "../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export interface StartRequest {
    origin: string;
    /**
     * Key for the iframe resource lock used to determine when the service is closing.
     *
     * Options:
     *   - proto3_optional = true
     */
    lockKey?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    observabilityGroup?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    signalTelemetryEnabled?: boolean;
}
/**
 * Iframe-to-worker RPCs.
 *
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export interface WorkerService {
    start: (request: StartRequest, options?: RequestOptions) => Promise<void>;
    stop: (request: void, options?: RequestOptions) => Promise<void>;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export enum ShellDisplay {
    NONE = 0,
    FULLSCREEN = 1
}
/**
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export interface AppContextRequest {
    /**
     * The display mode that shell should use.
     *
     * Options:
     *   - proto3_optional = true
     */
    display?: ShellDisplay;
    /**
     * The key of the joined space.
     *
     * Options:
     *   - proto3_optional = true
     */
    spaceKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * The id of the joined space.
     *
     * Options:
     *   - proto3_optional = true
     */
    spaceId?: string;
    /**
     * Target to redirect to after a successful invitation.
     *
     * Options:
     *   - proto3_optional = true
     */
    target?: string;
    /**
     * Shell cannot reload the app from within an iframe.
     *
     * Options:
     *   - proto3_optional = true
     */
    reset?: boolean;
}
/**
 * App RPCs for the shell.
 *
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export interface AppService {
    setContext: (request: AppContextRequest, options?: RequestOptions) => Promise<void>;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export enum ShellLayout {
    DEFAULT = 0,
    INITIALIZE_IDENTITY = 1,
    INITIALIZE_IDENTITY_FROM_INVITATION = 2,
    INITIALIZE_IDENTITY_FROM_RECOVERY = 9,
    IDENTITY = 3,
    SHARE_IDENTITY = 4,
    EDIT_PROFILE = 5,
    SPACE = 6,
    JOIN_SPACE = 7,
    STATUS = 8
}
/**
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export interface LayoutRequest {
    /**
     * Determins which panel of the shell is opened.
     */
    layout: ShellLayout;
    /**
     * Invitation code to join a space/identity.
     *
     * Options:
     *   - proto3_optional = true
     */
    invitationCode?: string;
    /**
     * Key of the space to share.
     *
     * Options:
     *   - proto3_optional = true
     */
    spaceKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Id of the space to share.
     *
     * Options:
     *   - proto3_optional = true
     */
    spaceId?: string;
    /**
     * Target to include in an invitation for redirecting after a successful invitation.
     *
     * Options:
     *   - proto3_optional = true
     */
    target?: string;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export interface InvitationUrlRequest {
    /**
     * Base URL for invitations.
     */
    invitationUrl: string;
    /**
     * Query parameter for device invitations.
     */
    deviceInvitationParam: string;
    /**
     * Query parameter for space invitations.
     */
    spaceInvitationParam: string;
}
/**
 * Shell UI RPCs.
 *
 * Defined in:
 *   {@link file://./../../dxos/iframe.proto}
 */
export interface ShellService {
    setLayout: (request: LayoutRequest, options?: RequestOptions) => Promise<void>;
    setInvitationUrl: (request: InvitationUrlRequest, options?: RequestOptions) => Promise<void>;
}
