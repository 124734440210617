//
// Copyright 2025 DXOS.org
//

import { subtleCrypto } from '@dxos/crypto';
import { PublicKey, IdentityDid } from '@dxos/keys';
import { ComplexMap } from '@dxos/util';

const IDENTITY_DIDS_CACHE = new ComplexMap<PublicKey, IdentityDid>(PublicKey.hash);

/**
 * Identity DIDs are generated by creating a keypair, and then taking the first 20 bytes of the SHA-256 hash of the public key and encoding them to multibase RFC4648 base-32 format (prefixed with B, see Multibase Table).
 * Inspired by how ethereum addresses are derived.
 */
export const createDidFromIdentityKey = async (identityKey: PublicKey): Promise<IdentityDid> => {
  const cachedValue = IDENTITY_DIDS_CACHE.get(identityKey);
  if (cachedValue !== undefined) {
    return cachedValue;
  }

  const digest = await subtleCrypto.digest('SHA-256', identityKey.asUint8Array());

  const bytes = new Uint8Array(digest).slice(0, IdentityDid.byteLength);
  const identityDid = IdentityDid.encode(bytes);
  IDENTITY_DIDS_CACHE.set(identityKey, identityDid);
  return identityDid;
};
