//
// Copyright 2024 DXOS.org
//

import base32Decode from 'base32-decode';
import base32Encode from 'base32-encode';

import { invariant } from '@dxos/invariant';

import { randomBytes } from './random-bytes';

/**
 * A unique identifier for an identity.
 * Identity DIDs are generated by creating a keypair, and then taking the first 20 bytes of the SHA-256 hash of the public key and encoding them to multibase RFC4648 base-32 format (prefixed with B, see Multibase Table).
 * @example did:halo:BA25QRC2FEWCSAMRP4RZL65LWJ7352CKE
 */
export type IdentityDid = string & { __IdentityDid: never };

export const IdentityDid = Object.freeze({
  byteLength: 20,
  encode: (value: Uint8Array): IdentityDid => {
    invariant(value instanceof Uint8Array, 'Invalid type');
    invariant(value.length === IdentityDid.byteLength, 'Invalid length');

    return (DID_PREFIX + MULTIBASE_PREFIX + base32Encode(value, 'RFC4648')) as IdentityDid;
  },
  decode: (value: IdentityDid): Uint8Array => {
    invariant(value.startsWith(DID_PREFIX + MULTIBASE_PREFIX), 'Invalid multibase32 encoding');

    return new Uint8Array(base32Decode(value.slice(10), 'RFC4648'));
  },
  isValid: (value: string): value is IdentityDid => {
    return (
      typeof value === 'string' && value.startsWith(DID_PREFIX + MULTIBASE_PREFIX) && value.length === ENCODED_LENGTH
    );
  },
  random: (): IdentityDid => {
    return IdentityDid.encode(randomBytes(IdentityDid.byteLength));
  },
});

/**
 * Denotes RFC4648 base-32 format.
 */
const MULTIBASE_PREFIX = 'B';

const DID_PREFIX = 'did:halo:';

const ENCODED_LENGTH = 42;
