//
// Copyright 2022 DXOS.org
//

// @generated by protoc-gen-es v2.1.0 with parameter "target=ts,import_extension=js"
// @generated from file dxos/edge/messenger.proto (package dxos.edge.messenger, syntax proto3)
/* eslint-disable */

//
// The messenger protocol supports routing messages between peers.
//

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Any } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_any } from "@bufbuild/protobuf/wkt";
import type { Message as Message$1 } from "@bufbuild/protobuf";

/**
 * Describes the file dxos/edge/messenger.proto.
 */
export const file_dxos_edge_messenger: GenFile = /*@__PURE__*/
  fileDesc("ChlkeG9zL2VkZ2UvbWVzc2VuZ2VyLnByb3RvEhNkeG9zLmVkZ2UubWVzc2VuZ2VyIoMBCgRQZWVyEhUKCHN3YXJtS2V5GAEgASgJSACIAQESGAoLaWRlbnRpdHlLZXkYAiABKAlIAYgBARIPCgdwZWVyS2V5GAMgASgJEhIKBXN0YXRlGAQgASgMSAKIAQFCCwoJX3N3YXJtS2V5Qg4KDF9pZGVudGl0eUtleUIICgZfc3RhdGUi9QEKB01lc3NhZ2USFgoJdGltZXN0YW1wGAEgASgJSACIAQESLgoGc291cmNlGAogASgLMhkuZHhvcy5lZGdlLm1lc3Nlbmdlci5QZWVySAGIAQESKQoGdGFyZ2V0GAsgAygLMhkuZHhvcy5lZGdlLm1lc3Nlbmdlci5QZWVyEhcKCnNlcnZpY2VfaWQYFCABKAlIAogBARIqCgdwYXlsb2FkGGQgASgLMhQuZ29vZ2xlLnByb3RvYnVmLkFueUgDiAEBQgwKCl90aW1lc3RhbXBCCQoHX3NvdXJjZUINCgtfc2VydmljZV9pZEIKCghfcGF5bG9hZCKUAQoMU3dhcm1SZXF1ZXN0Ej0KBmFjdGlvbhgBIAEoDjIoLmR4b3MuZWRnZS5tZXNzZW5nZXIuU3dhcm1SZXF1ZXN0LkFjdGlvbkgAiAEBEhEKCXN3YXJtS2V5cxgCIAMoCSInCgZBY3Rpb24SCAoESU5GTxAAEggKBEpPSU4QARIJCgVMRUFWRRACQgkKB19hY3Rpb24iSwoNU3dhcm1SZXNwb25zZRIQCghzd2FybUtleRgBIAEoCRIoCgVwZWVycxgCIAMoCzIZLmR4b3MuZWRnZS5tZXNzZW5nZXIuUGVlciIvCgtUZXh0TWVzc2FnZRIUCgdtZXNzYWdlGAEgASgJSACIAQFCCgoIX21lc3NhZ2ViBnByb3RvMw", [file_google_protobuf_any]);

/**
 *
 * Network peer.
 *
 * TODO(burdon): Distinguish between point-to-point and broadcast messaging.
 * TODO(burdon): Rename PeerInfo?
 *
 * @generated from message dxos.edge.messenger.Peer
 */
export type Peer = Message$1<"dxos.edge.messenger.Peer"> & {
  /**
   * TODO(burdon): Rename topic?
   *
   * @generated from field: optional string swarmKey = 1;
   */
  swarmKey?: string;

  /**
   * @generated from field: optional string identityKey = 2;
   */
  identityKey?: string;

  /**
   * @generated from field: string peerKey = 3;
   */
  peerKey: string;

  /**
   * *
   * Optional opeage data that represents peer state is updated on `join` request and all peers in swarm are notified.
   * Is not used for WebRTC protocol, but is useful for another purposes of Swarms, e. g. call room users states.
   *
   * @generated from field: optional bytes state = 4;
   */
  state?: Uint8Array;
};

/**
 * Describes the message dxos.edge.messenger.Peer.
 * Use `create(PeerSchema)` to create a new message.
 */
export const PeerSchema: GenMessage<Peer> = /*@__PURE__*/
  messageDesc(file_dxos_edge_messenger, 0);

/**
 *
 * Message envelope.
 *
 *
 * @generated from message dxos.edge.messenger.Message
 */
export type Message = Message$1<"dxos.edge.messenger.Message"> & {
  /**
   * @generated from field: optional string timestamp = 1;
   */
  timestamp?: string;

  /**
   * Routing.
   *
   * @generated from field: optional dxos.edge.messenger.Peer source = 10;
   */
  source?: Peer;

  /**
   * @generated from field: repeated dxos.edge.messenger.Peer target = 11;
   */
  target: Peer[];

  /**
   * / List of valid services is defined in the `EdgeService` enum. 
   * / Maybe be used to address a service at EDGE, but also to address components inside the client.
   *
   * @generated from field: optional string service_id = 20;
   */
  serviceId?: string;

  /**
   * @generated from field: optional google.protobuf.Any payload = 100;
   */
  payload?: Any;
};

/**
 * Describes the message dxos.edge.messenger.Message.
 * Use `create(MessageSchema)` to create a new message.
 */
export const MessageSchema: GenMessage<Message> = /*@__PURE__*/
  messageDesc(file_dxos_edge_messenger, 1);

/**
 *
 * Swarms are used to publish and subscribe to messages based on a topic.
 *
 *
 * @generated from message dxos.edge.messenger.SwarmRequest
 */
export type SwarmRequest = Message$1<"dxos.edge.messenger.SwarmRequest"> & {
  /**
   * @generated from field: optional dxos.edge.messenger.SwarmRequest.Action action = 1;
   */
  action?: SwarmRequest_Action;

  /**
   * TODO(burdon): Rename topic?
   *
   * @generated from field: repeated string swarmKeys = 2;
   */
  swarmKeys: string[];
};

/**
 * Describes the message dxos.edge.messenger.SwarmRequest.
 * Use `create(SwarmRequestSchema)` to create a new message.
 */
export const SwarmRequestSchema: GenMessage<SwarmRequest> = /*@__PURE__*/
  messageDesc(file_dxos_edge_messenger, 2);

/**
 * @generated from enum dxos.edge.messenger.SwarmRequest.Action
 */
export enum SwarmRequest_Action {
  /**
   * @generated from enum value: INFO = 0;
   */
  INFO = 0,

  /**
   * @generated from enum value: JOIN = 1;
   */
  JOIN = 1,

  /**
   * @generated from enum value: LEAVE = 2;
   */
  LEAVE = 2,
}

/**
 * Describes the enum dxos.edge.messenger.SwarmRequest.Action.
 */
export const SwarmRequest_ActionSchema: GenEnum<SwarmRequest_Action> = /*@__PURE__*/
  enumDesc(file_dxos_edge_messenger, 2, 0);

/**
 * @generated from message dxos.edge.messenger.SwarmResponse
 */
export type SwarmResponse = Message$1<"dxos.edge.messenger.SwarmResponse"> & {
  /**
   * @generated from field: string swarmKey = 1;
   */
  swarmKey: string;

  /**
   * *
   * The list of peers in the swarm.
   *
   * @generated from field: repeated dxos.edge.messenger.Peer peers = 2;
   */
  peers: Peer[];
};

/**
 * Describes the message dxos.edge.messenger.SwarmResponse.
 * Use `create(SwarmResponseSchema)` to create a new message.
 */
export const SwarmResponseSchema: GenMessage<SwarmResponse> = /*@__PURE__*/
  messageDesc(file_dxos_edge_messenger, 3);

/**
 *
 * Text message.
 *
 *
 * @generated from message dxos.edge.messenger.TextMessage
 */
export type TextMessage = Message$1<"dxos.edge.messenger.TextMessage"> & {
  /**
   * @generated from field: optional string message = 1;
   */
  message?: string;
};

/**
 * Describes the message dxos.edge.messenger.TextMessage.
 * Use `create(TextMessageSchema)` to create a new message.
 */
export const TextMessageSchema: GenMessage<TextMessage> = /*@__PURE__*/
  messageDesc(file_dxos_edge_messenger, 4);

